import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Icon, Span, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Юридична компанія Embersroot
			</title>
			<meta name={"description"} content={"Орієнтуйтеся в складнощах права з надійним провідником на вашому боці"} />
			<meta property={"og:title"} content={"Про нас | Юридична компанія Embersroot"} />
			<meta property={"og:description"} content={"Орієнтуйтеся в складнощах права з надійним провідником на вашому боці"} />
			<meta property={"og:image"} content={"https://embersroot.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://embersroot.com/img/7909222.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://embersroot.com/img/7909222.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://embersroot.com/img/7909222.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://embersroot.com/img/7909222.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://embersroot.com/img/7909222.png"} />
			<meta name={"msapplication-TileImage"} content={"https://embersroot.com/img/7909222.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
					Про нас
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
					В Embersroot ми більше, ніж команда юристів - ми ваші віддані партнери, які допоможуть вам орієнтуватися в правовому просторі. В основі нашої роботи лежить віра в те, що розуміння та доступ до юридичних консультацій має бути простим і надихаючим.
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
					Ми поєднуємо експертизу з емпатією, зосереджуючись на наданні індивідуальних рішень, які вирішують ваші унікальні юридичні проблеми.
					</Text>
				</Box>
				<Image
					src="https://embersroot.com/img/3.jpg"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Серце Embersroot
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257">
				Суть Юридичної агенції Embersroot полягає в тому, що ми прагнемо зробити закон зрозумілим для всіх - від приватних осіб, які стикаються з особистими юридичними питаннями, до бізнесу, який працює в складному правовому середовищі.
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
				Ми прагнемо перетворити залякуючий процес юридичної консультації на доступний, прозорий та сприятливий досвід. Наше серце б'ється від пристрасті до справедливості та невпинної боротьби за інтереси наших клієнтів.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Індивідуальні юридичні стратегії:{" "}
						</Span>
						Кожна справа привертає нашу повну увагу, гарантуючи, що стратегія буде точно адаптована до ваших потреб.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Емпатія та розуміння: 
						</Span>
						Ми ставимо на перше місце ваш душевний спокій, пропонуючи поради з терпінням і розумінням на кожному кроці.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Інноваційні юридичні рішення: 
						</Span>
						Використовуючи останні юридичні тенденції та технології, ми пропонуємо інноваційні та ефективні рішення.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				padding="0px 0px 0px 60px"
				margin="0px -10px 0px 0px"
				lg-margin="0px 10px 0px 10px"
				lg-padding="0px 0px 0px 0"
			>
				<Image src="https://embersroot.com/img/4.jpg" display="block" border-radius="25px" />
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="40%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Побудуйте свою юридичну подорож разом з нами{" "}
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
					Оберіть Embersroot своїм союзником у світі юриспруденції. З нами ви не просто орієнтуєтеся в законодавстві - ви створюєте історію розширення можливостей та успіху. Давайте працювати разом, щоб перетворити юридичні виклики на перемоги.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});